@charset "UTF-8";
.fontGraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.MuiMenuItem-root {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
}
.dropbtn {
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 56px;
  cursor: pointer;
  position: relative;
  text-align: left;
  padding-left: 24px;
  border: none;
}
.spanText {
  margin-top: -23px;
  display: flex;
  margin-left: 34px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  cursor: pointer;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

/* .dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
    margin-top: 13px;

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width:100%;
  border-radius: 0px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
} */
.languageBtn {
  width: 100%;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  color: #565656;
  width: 85%;
  outline: none;
  margin-left: 5px;
}
.langOption {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 15px 15px;
  width: 100%;
}
.colseModelbtn {
  position: absolute;
  top: 13px;
  right: 17px;
}

.purchaseSetting {
  font-size: 25px;
  text-align: center;
  color: #828282;
  font-family: Inter;
  padding-top: 73px;
}

.subscribe {
  color: #828282;
  text-decoration: none;
  cursor: pointer;
}

.subscribe:hover {
  color: #000;
  text-decoration: underline;
}

.rdrDateRangePickerWrapper {
  margin-left: -200px;
}

.fontStyleLeg1 .recharts-legend-wrapper {
  position: absolute !important;
  width: 90px !important;
  top: 0px !important;
  /* left: 410px !important; */
}

.fontStyleLeg1 .recharts-legend-item-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2d3436;
  margin-top: 100;
}

.fontStyleLeg .recharts-legend-wrapper {
  position: absolute !important;
  width: 170px !important;
  top: 0px !important;
  left: 340px !important;
}

.fontStyleLeg .recharts-legend-item-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2d3436;
  margin-top: 100;
}

.fontStylePi .recharts-legend-wrapper {
  position: absolute !important;
  width: 170px !important;
  height: 140px !important;
  top: 30px !important;
  left: 315px !important;
}

.fontStylePi .recharts-legend-item-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #2d3436;
  margin-top: 100;
}

.iconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  left: 82%;
}

.iconContainer .iconDivWrapper {
  width: 50px;
  height: 52px;
  border-radius: 10px;
  padding: 13px;
  background-color: #dcdada;
  margin: 6px;
  cursor: pointer;
}

.GraphContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
  padding-top: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.GraphContainer .graphDivWrapper {
  width: 500px;
  border: 2px solid #dcdada;
  border-radius: 10px;
  margin-bottom: 35px;
}
.GraphContainer .graphDivWrapper .customStyleGraph{
  width: auto;
  border: 2px solid #dcdada;
  border-radius: 10px;
  margin-bottom: 35px
}

.GraphContainer .graphDivWrapper .divAlignItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px solid #dcdada;
  padding: 0px 18px;
  height: 70px;
}

.GraphContainer .graphDivWrapper .divAlignItem .btnGraph {
  border: 2px solid #bdbdbd;
  height: 40px;
  margin-top: 13px;
  border-radius: 10px;
  position: absolute;
  left: 610px;
}

.GraphContainer .graphDivWrapper .divAlignItem .showSelect {
  position: relative;
  top: 60px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px;
  z-index: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.GraphContainer .graphDivWrapper .divAlignItem .showSelect .customDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 2px solid #dcdfdf;
  padding: 9px;
}

.GraphContainer .graphDivWrapper .divAlignItem .selectStyle {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
}

.GraphContainer .border-div {
  border: 2px solid #dcdada;
  border-radius: 0px 0px 10px 10px;
  margin-top: -6px;
}

.GraphContainer .graphDivWrapper1 {
  width: 500px;
  margin-bottom: 35px;
}

.GraphContainer .graphDivWrapper1 .divAlignItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 2px solid #dcdada;
  border-radius: 10px;
  padding: 0px 18px;
  height: 70px;
}

.GraphContainer .graphDivWrapper1 .divAlignItem .btnGraph {
  border: 2px solid #bdbdbd;
  height: 40px;
  margin-top: 13px;
  border-radius: 10px;
  position: absolute;
  left: 610px;
}

.GraphContainer .graphDivWrapper1 .divAlignItem .showSelect {
  position: relative;
  top: 60px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px;
  z-index: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.GraphContainer .graphDivWrapper1 .divAlignItem .showSelect .customDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 2px solid #dcdfdf;
  padding: 9px;
}

.GraphContainer .graphDivWrapper1 .borderTable {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.19);
}

.GraphContainer .graphDivWrapper1 .tableCountriesFonts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2d3436;
}

.GraphContainer .border-div2 {
  border: 2px solid #dcdada;
  border-radius: 0px 0px 10px 10px;
  margin-top: -6px;
}

.GraphContainer .graphDivWrapper2 {
  width: 500px;
  margin-bottom: 35px;
}

.GraphContainer .graphDivWrapper2 .divAlignItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 2px solid #dcdada;
  border-radius: 10px;
  padding: 0px 18px;
  height: 70px;
}

.GraphContainer .graphDivWrapper2 .divAlignItem .btnGraph {
  border: 2px solid #bdbdbd;
  height: 40px;
  margin-top: 13px;
  border-radius: 10px;
  position: absolute;
  left: 610px;
}

.GraphContainer .graphDivWrapper2 .divAlignItem .showSelect {
  position: relative;
  top: 60px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px;
  z-index: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.GraphContainer .graphDivWrapper2 .divAlignItem .showSelect .customDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 2px solid #dcdfdf;
  padding: 9px;
}

.GraphContainer .graphDivWrapper2 .borderTable {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.19);
}

.GraphContainer .graphDivWrapper2 .tableCityFonts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2d3436;
}

.GraphContainer1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
  padding-top: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.GraphContainer1 .graphDivWrapper {
  width: 100%;
  border: 2px solid #dcdada;
  border-radius: 10px;
  margin-bottom: 35px;
}

.GraphContainer1 .graphDivWrapper .divAlignItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom: 2px solid #dcdada;
  padding: 15px;
}

.GraphContainer1 .graphDivWrapper .divAlignItem .fileImg {
  background-color: #dcdada;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  margin-right: 15px;
  height: 45px;
  margin-top: 8px;
}

.GraphContainer1 .graphDivWrapper .divAlignItem .btnGraph {
  border: 2px solid #bdbdbd;
  margin-top: 10px;
  border-radius: 10px;
}

.GraphContainer1 .graphDivWrapper .divAlignItem .MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 1350px;
}

.GraphContainer1 .graphDivWrapper .divAlignItem .divForAddCol {
  position: absolute;
  margin-top: 64px;
  margin-left: 20px;
  background: #fff;
  height: auto;
  right: 50px;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
}

.GraphContainer1 .graphDivWrapper .divAlignItem .divForAddCol .fontAddCol {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  /* text-align: center; */
  color: #2d3436;
  padding: 3px;
}

.GraphContainer1 .graphDivWrapper .MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 1350px;
}

.GraphContainer1 .graphDivWrapper .fontForGraphTable {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #828282 !important;
}

.GraphContainer1 .graphDivWrapper .fontForGraphTableRows {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #2d3436;
  text-transform: capitalize;
}

.GraphContainer1 .graphDivWrapper .fontForGraphTableRows1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #2d3436;
}

.compnyName {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height, or 267% */
  width: 70%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #fff;
}

.compnyName:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.logoArea {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid;
}

.prodLogo {
  background-color: #dfdada;
  padding: 2px;
  border-radius: 5px;
}

.compLogo {
  background-color: #dfdada;
  padding: 2px;
  border-radius: 5px;
  position: relative;
  left: 90px;
  top: 20px;
  z-index: 9999;
  cursor: pointer;
}

.modelCompanyLogo {
  width: 16%;
  border-radius: 10%;
}

.uploadLogoPlace {
  width: 92px;
  height: 92px;
  overflow: hidden;
  border-radius: 15px;
  position: absolute;
  top: 26px;
  left: 27px;
  border: 1px solid gray;
}

.uploadLogoPlaceuserSide {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 15px;
  position: absolute;
  top: 150px;
  border: 1px solid gray;
}

.uploadLogoPlaceSidebar {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 15px;
  position: absolute;
  top: 15px;
  left: 40px;
  border: 1px solid gray;
}

.modelCompanyLogo {
  width: 16%;
  border-radius: 10%;
}

.colmds {
  background-color: #fff;
}

#signInUser {
  margin-right: 4px;
  background-color: #f0eded;
  padding: 7px;
}

#signInUser:hover {
  background-color: #d6d3d3;
}

#updateUser {
  margin-right: 4px;
  background-color: #f0eded;
}

#updateUser:hover {
  background-color: #d6d3d3;
}

#deleteUser {
  margin-right: 4px;
}

#deleteUser:hover {
  background-color: rgba(248, 145, 181, 0.5);
  color: #000;
}

.authloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9;
}

.authloaderWrapper .authLoader {
  margin-bottom: 8px;
}

.navactive .MuiButtonBase-root {
  background-color: rgba(66, 147, 228, 0.644);
}

.navactive .MuiButtonBase-root .XColor {
  font-weight: 700;
}

.loginWrapper {
  height: 100vh;
  width: 100%;
  background: url("./Assets/login_bg.jpg") center no-repeat;
  background-size: cover;
}

.loginWrapper .loginCard {
  height: 75vh;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  border-radius: 10px;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.loginWrapper .loginCard .loginFormWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 200px;
}

.CategoryWrapper {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ModalWrapper {
  padding: 10px;
  text-align: center;
  padding-top: 95px;
}

.ModalWrapper h1 {
  color: #000;
}

.ModalWrapper .Typo {
  color: #000;
  font-size: 17px;
}

.ModalWrapper .Delbutton {
  margin: 10px;
}

.ModalWrapper .DeleteMsg {
  color: #059205;
}

.progressPara {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 26px;
  font-family: Inter;
  font-style: normal;
  color: #000000;
}

.uploadButn {
  border: 1px solid #d3d1d1;
  padding: 0px 15px;
  border-radius: 7px;
  font-size: 15px;
}

.productStatus {
  margin-left: 10px;
  padding: 6px 30px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.CircularProgressbar {
  width: 35%;
  vertical-align: middle;
}

.ReactCrop {
  position: relative;
  display: initial;
  cursor: crosshair;
  overflow: hidden;
  /* max-width: 100%; */
}

.ReactCrop__image {
  display: initial;
  height: 500px;
  -ms-touch-action: none;
  touch-action: none;
}

.serviceBtnLanding {
  position: relative;
  width: 21%;
  height: 55px;
  top: 10px;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  background: #bdbdbd;
  border-radius: 7px;
}

.serviceBtnLanding:hover .serviceBtnLanding {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.confirmationModalPublish {
  text-align: center;
  padding: 34px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 62px;
}

.confirmationModalPublish .DeleteBtnPublish {
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  padding: 3px 10px;
  border-radius: 6px;
  margin: 10px;
  width: 55%;
  height: 50px;
  text-transform: capitalize;
}

.confirmationModalPublish .DeleteBtnPublish:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.confirmationModalPublish2 {
  text-align: center;
  padding: 34px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 35px;
}

.confirmationModalPublish2 .DeleteBtnPublish {
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  padding: 3px 10px;
  border-radius: 6px;
  margin: 10px;
  width: 55%;
  height: 50px;
  text-transform: capitalize;
}

.confirmationModalPublish2 .DeleteBtnPublish:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.published {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -18px;
  margin-left: 17px;
  padding-left: 17px;
  text-align: left;
  letter-spacing: 0.25px;
  color: #999999;
}

.infoItemPlan {
  margin: 10px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
}

.infoItemPlanDetail {
  margin: 20px 0;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
  font-weight: 500;
  padding-bottom: 10px;
}

.detailBack {
  background: #ffffff;
  color: #828282;
  font-size: 25px;
  padding: 10px;
  width: 100%;
  border-radius: 12px;
  -webkit-box-shadow: 2px 3px 13px 2px rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 2px 3px 13px 2px rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.title {
  color: #828282;
  font-weight: 400;
  font-size: 25px;
  font-weight: bold;
}

.amount {
  color: #828282;
  font-weight: 400;
  font-size: 22px;
}

.date {
  color: #828282;
  font-weight: 400;
  font-size: 22px;
}

.saveCroppedImg {
  background-color: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border: none;
  padding: 13px 78px;
  color: #fff;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  border-radius: 16px;
  position: relative;
  left: 240px;
  bottom: 25px;
  cursor: pointer;
  letter-spacing: 0.25px;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.saveCroppedImg:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.saveBtnCrop {
  background: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 19%;
  padding: 5px 73px;
  border: none;
  margin-top: -30px;
  margin-left: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  cursor: pointer;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.saveBtnCrop:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.cancelCroppedImg {
  background-color: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border: none;
  padding: 13px 78px;
  color: #fff;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  border-radius: 16px;
  position: relative;
  left: 390px;
  bottom: 25px;
  cursor: pointer;
  letter-spacing: 0.25px;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.cancelCroppedImg:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.cancelBtnCrop {
  background: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 19%;
  padding: 5px 67px;
  border: none;
  margin-top: -45px;
  margin-left: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  cursor: pointer;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.cancelBtnCrop:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.plans {
  font-size: 60px;
  line-height: 72.61px;
  text-align: center;
  font-weight: 600;
}

.plansNew {
  font-size: 70px;
  line-height: 104px;
  letter-spacing: -3px;
  text-align: center;
  font-weight: 300;
  position: relative;
}

.textRight {
  width: 40%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 92px;
}

.textRightResp {
  width: 40%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.leftImg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -40%;
}

.mainClass {
  width: 20%;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  left: -20%;
}

.mainClassResp {
  width: 40%;
  position: relative;
  -webkit-box-flex: 0.6;
  -ms-flex: 0.6;
  flex: 0.6;
  left: -11%;
}

.crossImg {
  position: absolute;
  left: 4px;
  top: 154px;
}

.crossImg2 {
  position: absolute;
  left: 4px;
  top: 182px;
}

.crossImg3 {
  position: absolute;
  left: 4px;
  top: 210px;
}

.mainClassSecondResp {
  width: 40%;
  position: relative;
  -webkit-box-flex: 0.6;
  -ms-flex: 0.6;
  flex: 0.6;
  right: -51%;
}

.leftImgResp {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -42%;
}

.contentParagraph {
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  line-height: 32px;
}

.blogName {
  position: relative;
  right: 80px;
  bottom: 20px;
}

.blogNameResp {
  position: relative;
  right: 50px;
  bottom: 20px;
}

.paraNew1013 {
  text-align: center;
  font-size: 18px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 17%;
  top: 40px;
  line-height: 32px;
  width: 715px;
}

.subTitlePlans {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  position: relative;
  bottom: 30px;
}

.annualPlan {
  padding-right: 10px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}

.monthlyPlan {
  padding-left: 10px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}

.toggleArea {
  text-align: center;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 38%;
  top: -5px;
}

.toggleArea1100 {
  text-align: center;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 14%;
  top: -5px;
}

.helpWe {
  text-align: center;
  font-size: 70px;
  font-weight: 300;
  line-height: 104px;
  letter-spacing: -3px;
}

.paraNew {
  text-align: center;
  font-size: 18px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 20%;
  top: 40px;
  line-height: 32px;
  width: 840px;
}

.para {
  text-align: center;
  font-size: 15px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 15%;
  line-height: 32px;
}

.para1536 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 20%;
  line-height: 32px;
}

.para1046 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 20%;
  line-height: 32px;
}

.para1560 {
  text-align: center;
  font-size: 18px;
  width: 840px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 21%;
  line-height: 32px;
}

.paraBlog {
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  width: 840px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 19%;
  top: 50px;
}

.paraBlog1100 {
  text-align: center;
  line-height: 32px;
  font-size: 11px;
  width: 840px;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 10%;
  top: 35px;
}

.paraBlogDetailDesc {
  text-align: justify;
  line-height: 32px;
  font-size: 18px;
  width: 100%;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 4%;
  padding-top: 7%;
}

.paraBlogDetailDesc1100 {
  text-align: justify;
  line-height: 32px;
  font-size: 11px;
  width: 100%;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 6%;
  padding-top: 7%;
}

.paraBlogDescription {
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  width: 100%;
  position: relative;
  -webkit-transform: translate(-50px, -50px);
  transform: translate(-50px, -50px);
  left: 4%;
}

@media only screen and (max-width: 360px) {
  .para {
    text-align: center;
    font-size: 12px;
    width: 770px;
    position: relative;
    -webkit-transform: translate(-50px, -50px);
    transform: translate(-50px, -50px);
    left: 16%;
  }
}

.weHelpu {
  text-align: center;
  font-size: 70px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
}

.weHelpuResp {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
  letter-spacing: -3px;
  padding-left: 7%;
}

.weHelpuResp360 {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  line-height: 104px;
  font-family: Inter;
  font-style: normal;
  letter-spacing: -3px;
  padding-left: 7%;
}

.enhance {
  font-weight: 800;
}

.paraGraph {
  width: 844px;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.paraGraphResp {
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 53%;
}

.paraGraphResp1093 {
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  font-style: normal;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 53%;
  top: 25px;
}

.leftCard {
  background: #396996;
  border-radius: 16px;
  position: absolute;
  width: 32%;
  height: 100%;
  left: -35px;
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: local("Inter"), url(./Assets/fonts/Inter-Black.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: local("Inter"),
    url(./Assets/fonts/Inter-ExtraBold.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter"), url(./Assets/fonts/Inter-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter"), url(./Assets/fonts/Inter-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter"), url(./Assets/fonts/Inter-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: local("Inter"), url(./Assets/fonts/Inter-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: local("Inter"), url(./Assets/fonts/Inter-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: local("Inter"),
    url(./Assets/fonts/Inter-ExtraLight.ttf) format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: local("Inter"), url(./Assets/fonts/Inter-Thin.ttf) format("opentype");
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Inter", sans-serif !important;
  color: #2d3436;
}

body {
  overflow-x: hidden;
}

.element::-webkit-scrollbar {
  width: 0 !important;
}

.element::-webkit-scrollbar {
  display: none;
}

#root {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

#root .bottomSpacing {
  margin-bottom: 16px;
}

#root .bottomMaxSpacing {
  margin-bottom: 100px;
}

#root .verticalSpacing {
  margin: 64px 0;
}

#root .bgTheme {
  background-color: #fff !important;
  color: #2d3436;
}

#root .listItems {
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  width: 67%;
}

#root .listItemsResp {
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  width: 45%;
}

#root .bolder {
  font-weight: bolder;
}

#root .linkStyle {
  text-decoration: none;
}

#root body::-webkit-scrollbar {
  width: 2px;
}

#root ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#root ::-webkit-scrollbar-thumb {
  background: #ddd;
}

#root ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#root .DemoText {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  padding: 0px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding-right: 50px;
  margin-top: 15px;
  color: #828282;
}

#root .Lottiewrapper {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

#root .Lottiewrapper .typo {
  font-family: Inter;
  font-style: normal;
  color: #808080;
}

#root .web {
  display: block;
}

#root .responsive {
  display: none;
}

#root .bold {
  font-weight: 600;
}

#root .noBorder {
  border: none;
}

#root .expandIcon {
  position: absolute;
  left: 2%;
  top: 31%;
}

#root .rounded {
  border-radius: 15px;
}

#root .roundedPlansPricing {
  border-radius: 8px;
}

#root .containerSpacing {
  padding: 0 100px;
}

#root .containerSpacingdashBoard {
  padding: 0 5px;
}

#root .flexChildCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#root .themeFontColor {
  color: #2d3436;
}

#root .lineHeight {
  line-height: 32px;
}

#root .txtShadow {
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

#root .txtWhiteShadow {
  text-shadow: 0px 4px 0px #ffffff;
}

#root .themeBtnSuccess {
  background-color: #bdbdbd;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  font-weight: 700;
  padding: 15px 35px;
  margin-left: 19px;
  margin-top: 30px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

#root .themeBtnSuccess:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#root .themeBtn {
  background-color: #bdbdbd;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  font-weight: 700;
  padding: 8px 30px;
  margin-left: 19px;
}

#root .themeBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#root .themeBtnSelected {
  background-color: #0d0d0d;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  font-weight: 700;
  padding: 8px 30px;
  margin-left: 19px;
}

#root .themeBtnSelected:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#root .flexWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#root .themeGrayBtn {
  background-color: #e3e3e3;
  color: #8c8c8c;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  padding: 4px 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#root .compactTxt {
  letter-spacing: -3px;
}

#root .hero {
  padding-top: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

#root .hero .rect_ele {
  position: fixed;
  width: 100%;
  height: 700px;
  left: 46%;
  top: -17.5%;
  background: #92b4d3;
  border-radius: 99px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -2;
  opacity: 0;
}

#root .hero .heroTitle {
  font-size: 5.5rem;
  margin-bottom: 16px;
}

#root .hero .tryButton {
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  font-size: 28px;
  padding: 12px 32px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  letter-spacing: -0.5px;
  text-transform: none;
  border-radius: 16px;
  margin: 80px auto;
}

#root .AR {
  position: relative;
  margin: 0 8px;
}

#root .AR::before {
  content: "";
  background: rgba(146, 180, 211, 0.8);
  border-radius: 30px;
  width: 150px;
  height: 40px;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

#root .navbar {
  border-bottom: 1px solid #ddd;
  padding-top: 12px;
  padding-bottom: 12px;
}

#root .navbar button {
  font-size: 24px;
}

#root .banner {
  margin: 32px 0 100px 0;
}

#root .text-center {
  text-align: center;
}

#root .view_in_space_btn {
  font-size: 28px;
  padding: 8px 32px;
  width: 88%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  letter-spacing: -0.5px;
  border-radius: 16px;
  margin: 0 auto;
  font-weight: 500;
}

#root .view_in_space_btn span::first-letter {
  text-transform: none;
}

#root .seeMoreBtn {
  line-height: 48px;
  width: 361px;
  border-radius: 15px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 100px;
  margin-bottom: 0;
}

#root .pricingCard1100 {
  width: 230px;
  height: 730px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#root .pricingCard1100 .beginner {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard1100 .price {
  font-size: 36px;
  font-weight: 700;
  line-height: 15.57px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard1100 .month {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard1100 .bottomBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  top: 9%;
}

#root .pricingCard1100 .bottomBorder2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  top: 43%;
}

#root .pricingCard1100 .modelTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justify;
  width: 165px;
  margin-left: 20%;
}

#root .pricingCard1100 .imgWrapper {
  height: 900px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

#root .pricingCard1100 .buyNow {
  position: absolute;
  left: 43%;
  top: 235px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 41px;
  width: 175px;
  font-size: 12px;
  line-height: 19.2px;
}

#root .pricingCard1100 .buyNowSelected {
  position: absolute;
  left: 43%;
  top: 220px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 41px;
  width: 175px;
  font-size: 12px;
  line-height: 19.2px;
  background: #000000;
}

#root .pricingCard1100 .buyNow2 {
  position: absolute;
  width: 180px;
  height: 40px;
  background: #000;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  /* z-index: 9999; */
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 32%;
}

#root .pricingCard1100 .buyBtn {
  position: relative;
  top: 10px;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 19.2; */
  text-align: center;
}

#root .pricingCard1100 .modelviews {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #999999;
}

#root .pricingCard1100 .vectorArea {
  position: absolute;
  top: 38%;
  left: 10%;
}

#root .pricingCard1100 .vectorArea2 {
  position: absolute;
  top: 71%;
  left: 6%;
}

#root .pricingCard1100 .hostedModels {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  position: relative;
  bottom: 2px;
  font-family: Inter;
}

#root .pricingCard1100 .gamefied {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  position: relative;
  bottom: 2px;
  font-family: Inter;
}

#root .pricingCard1100 .paddingTop {
  padding-top: 8px;
}

#root .pricingCard1100 .paddingTop2 {
  padding-top: 15px;
}

#root .pricingCard1100 .varient {
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  font-family: Inter;
}

#root .pricingCard1100 .varient2 {
  font-weight: 600;
  font-size: 12px;
  font-family: Inter;
}

#root .pricingCard {
  width: 272px;
  height: 730px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#root .pricingCard .beginner {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard .price {
  font-size: 36px;
  font-weight: 700;
  line-height: 15.57px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard .month {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  font-family: Inter;
}

#root .pricingCard .bottomBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  top: 9%;
}

#root .pricingCard .bottomBorder2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  top: 43%;
}

#root .pricingCard .modelTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justify;
  width: 165px;
  margin-left: 20%;
  letter-spacing: 0;
}

#root .pricingCard .imgWrapper {
  height: 900px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

#root .pricingCard .buyNow {
  position: absolute;
  left: 43%;
  top: 235px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 41px;
  width: 175px;
  font-size: 12px;
  line-height: 19.2px;
}

#root .pricingCard .buyNow2 {
  position: absolute;
  width: 180px;
  height: 40px;
  background: #000;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  /* z-index: 9999; */
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 32%;
}

#root .pricingCard .buyBtn {
  position: relative;
  top: 10px;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 19.2; */
  text-align: center;
}

#root .pricingCard .modelviews {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #999999;
}

#root .pricingCard .vectorArea {
  position: absolute;
  top: 40%;
  left: 10%;
}

#root .pricingCard .vectorArea2 {
  position: absolute;
  top: 73%;
  left: 6%;
}

#root .pricingCard .hostedModels {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  position: relative;
  bottom: 2px;
  font-family: Inter;
}

#root .pricingCard .gamefied {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  position: relative;
  bottom: 2px;
  font-family: Inter;
}

#root .pricingCard .paddingTop {
  padding-top: 8px;
}

#root .pricingCard .paddingTop2 {
  padding-top: 15px;
}

#root .pricingCard .varient {
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  font-family: Inter;
}

#root .pricingCard .varient2 {
  font-weight: 600;
  font-size: 12px;
  font-family: Inter;
}

#root .modelCard {
  height: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#root .modelCard .modelTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  font-family: Inter;
}

#root .modelCard .imgWrapper {
  height: 250px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

#root .modelCard .modelviews {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #999999;
}

#root .modelCard img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}

#root .modelCard:hover img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

#root .modelCard:hover .themeGrayBtn {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

#root .browseHead {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.5px;
}

#root .helpYouRight {
  height: 577px;
  background: #8d8cba;
  border-radius: 16px 0px 0px 16px;
  height: 559 px;
  /* background: rgb(57, 105, 150); */
}

#root .sectionInnerSpacingBlogs {
  padding: 10px 0;
}

#root .sectionInnerSpacingAr {
  padding: 90px 0;
  text-align: center;
}

#root .sectionInnerSpacingBrowse {
  padding: 0px 0;
}

#root .sectionInnerSpacing {
  padding: 55px 0;
}

#root .sectionInnerSpacingPricing {
  padding: 0px 0;
}

#root .sectionInnerSpacingAbout1 {
  padding: 0px 0;
}

#root .sectionInnerSpacingAbout2 {
  padding: 22px 0;
}

#root .serviceHead {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
}

#root .serviceSubHead {
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.25px;
}

#root .serviceWrapper {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#root .serviceWrapper .serviceTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

#root .serviceWrapper .serviceDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-transform: capitalize;
}

#root .serviceWrapper .serviceBtn {
  position: relative;
  width: 50%;
  height: 40px;
  left: 0px;
  top: 60px;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  background: #bdbdbd;
  border-radius: 7px;
}

#root .serviceWrapper:hover .serviceBtn {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

#root .pricingTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 77px;
  /* identical to box height */
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
  letter-spacing: -1px;
  padding-bottom: 100px;
}

#root .priceTableWrapper {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0 64px 64px 64px;
}

#root .priceTableWrapper .priceTabHead {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 48px;
  height: 113px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  color: #adadad;
  border-bottom: 1px solid rgba(205, 205, 205, 0.5);
}

#root .priceTableWrapper .planTitle {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#root .priceTableWrapper .alignCenter {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#root .priceTableWrapper .priceTabData {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  height: 113px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: -0.5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #000000;
  border-bottom: 1px solid rgba(205, 205, 205, 0.5);
}

#root .priceTableWrapper .buy_now {
  background-color: #bdbdbd;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  font-weight: 700;
  padding: 8px 30px;
  border-radius: 10px;
}

#root .priceTableWrapper .buy_now:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#root .noBorder {
  border: none !important;
}

#root .VAS .VAStitle {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: #797979;
  position: relative;
}

#root .VAS .VAStitle::before {
  content: "";
  height: 25%;
  width: 10%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  background: #eaeaea;
  border-radius: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

#root .VAS .VASsubtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #2d3436;
  margin-top: 8px;
}

#root .VAS .VASwrapper {
  padding: 48px;
}

#root .VAS .VASwrapper .VASitem {
  border: 1px solid #d0d0d0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  width: 75%;
  padding: 16px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  letter-spacing: -0.5px;
  margin: 0 auto;
  color: #4e4e4e;
}

#root .VAS .touchBtnWrapper {
  padding: 16px;
}

#root .VAS .touchBtnWrapper .touchBtn {
  background: #bdbdbd;
  border-radius: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.25px;
  color: #ffffff;
  padding: 12px 40px;
  text-transform: none;
}

#root .footer {
  position: relative;
  overflow: hidden;
}

#root .footer .bgElement {
  position: absolute;
  width: 300%;
  top: 64px;
  left: -100px;
  right: 0;
  bottom: 0;
  background: #d3e1ed;
  -webkit-transform: rotate(-3deg);
  transform: rotate(-3deg);
  z-index: -1;
  opacity: 0.4;
}

#root .footer .followTitle,
#root .footer .infoTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  color: #adadad;
}

#root .footer .infoTitle {
  margin-top: 32px;
}

#root .footer .iconWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#root .footer .iconWrapper .followicon {
  font-size: 24px;
  margin-right: 40px;
  color: #828282;
  margin-top: 16px;
}

#root .footer .infoWrapper .infoItem {
  margin: 20px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
  cursor: pointer;
}

#root .footer .email {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.5px;
  /* Gray 3 */
  color: #828282;
}

#root .footer .contactInput {
  background: #c2c2c240;
  border-radius: 16px;
  border: none;
  padding: 12px 16px;
  font-size: 18px;
  margin: 12px 0;
  border: 2px solid transparent;
  width: 85%;
  cursor: pointer;
}

#root .footer .contactInput:focus {
  border: 2px solid #828282;
  outline: none;
}

#root .footer .inputIcon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12%;
  font-size: 22px;
  color: #b3b3b3;
}

#root .footer .messageInput {
  height: 44px;
  width: 90%;
  font-family: Inter;
  font-style: normal;
}

#root .footer .messageInputIcon {
  right: 8%;
  top: 40px !important;
}

#root .footer .submitBtn {
  margin: 12px 0;
  padding: 16px 50px;
  background: #828282;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

#root .notiMessage {
  text-transform: uppercase;
}

#root .animateFadeUp {
  opacity: 0;
  -webkit-animation: fadeUp 1s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
  animation: fadeUp 1s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
}

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

#root .animateFadeUpDelay {
  opacity: 0;
  -webkit-animation: fadeUp 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94)
    forwards;
  animation: fadeUp 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
}

@keyframes fadeUp {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

#root .animateFadeRightDelay {
  opacity: 0;
  -webkit-animation: fadeRight 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94)
    forwards;
  animation: fadeRight 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
}

@-webkit-keyframes fadeRight {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#root .animateFadeLeftDelay {
  opacity: 0;
  -webkit-animation: fadeLeft 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94)
    forwards;
  animation: fadeLeft 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
}

@-webkit-keyframes fadeLeft {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeLeft {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#root .animateRotate {
  opacity: 0;
  -webkit-animation: rotate 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94)
    forwards;
  animation: rotate 1.5s 1s cubic-bezier(0.18, 0.56, 0.64, 0.94) forwards;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(100%, 100%) rotate(100deg);
    transform: translate(100%, 100%) rotate(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(-45deg);
    transform: translate(0, 0) rotate(-45deg);
    opacity: 0.4;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(100%, 100%) rotate(100deg);
    transform: translate(100%, 100%) rotate(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(-45deg);
    transform: translate(0, 0) rotate(-45deg);
    opacity: 0.4;
  }
}

.viewInYourSpace {
  background-color: #bdbdbd;
  -webkit-box-shadow: "1px 2px 5px rgba(0, 0, 0, 0.1)";
  box-shadow: "1px 2px 5px rgba(0, 0, 0, 0.1)";
  border-radius: 15px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 16px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 74%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 48px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.viewInYourSpace:hover {
  background-color: rgba(146, 180, 211, 0.8);
}

@media (max-width: 768px) {
  #root .serviceWrapper .serviceBtn {
    width: 80%;
  }
}

#qrCodeWrapper {
  height: 250px;
  width: 250px;
  margin: 0 auto;
}

#qrtext {
  font-size: 12px;
  text-align: center;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: absolute;
  bottom: 38px;
  left: 50%;
  padding: 6px 8px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #fff;
  /* color: #fff; */
}

#qrtext:hover {
  background-color: #ececec;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  /* 15% from the top and centered */
  padding: 32px 20px;
  -webkit-transform: translate(-50%, -200%);
  transform: translate(-50%, -200%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #888;
  width: 400px;
  z-index: 9;
  border-radius: 16px;
  text-align: center;
  /* Could be more or less, depending on screen size */
}

.modal-content h1 {
  margin: 4px;
}

.contentArea {
  width: 300px;
  margin: 0 auto;
}

.modal-content p {
  width: 300px;
  margin: 8px auto;
}

.slideIn {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
}

.closeBtn {
  display: inline-block;
  margin: 8px auto;
  border-radius: 8px;
  padding: 12px 32px;
  min-width: 200px;
  cursor: pointer;
  /* background-color: #4a4fdf; */
  font-size: 14px;
  /* color: #fff; */
  width: 100px;
  border: 1px solid #222;
}

.modelViewer {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.modelViewer ::-webkit-scrollbar {
  width: 1px;
}

.modelViewer ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modelViewer ::-webkit-scrollbar-thumb {
  background: #ddd;
}

.modelViewer ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modelViewer .brand {
  position: absolute;
  z-index: 999;
  top: 8px;
  left: 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #2d3436;
}

.modelViewer .brand a {
  color: inherit;
  text-decoration: none;
}

.modelViewer .card {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 8px;
  width: 88%;
}

.modelViewer .card:hover {
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modelViewer .cardFirstPreview {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 8px;
  width: 88%;
}

.modelViewer .cardFirstPreview:hover {
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modelViewer .cardPreview {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 8px;
  width: 88%;
}

.modelViewer .cardPreview:hover {
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modelViewer .modelTitlePreview {
  font-style: normal;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 0px;
  line-height: 12px;
  color: #000000;
}

.modelViewer .modelTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 0px;
  line-height: normal;
  color: #000000;
}

.modelViewer .modelTitleAbout {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  letter-spacing: -1px;
  margin-bottom: 0px;
  line-height: normal;
  color: #000000;
}

.modelViewer .subtitlePreview {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  margin-left: 0px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
}

.modelViewer .subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 0px;
}

.modelViewer .buyNow {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* or 133% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.modelViewer .buyNowPreview {
  font-size: 18px;
  line-height: 24px;
  margin-left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 5px;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
}

.modelViewer .buy_sub {
  font-weight: 100;
  font-size: 12px;
  /* or 133% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin: 0;
}

.modelViewer .buy_subPreview {
  font-weight: 500;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: 0px;
  margin: 0;
}

.modelViewer .fixBox {
  max-height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.modelViewer .variant_head {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  /* identical to box height, or 311% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.modelViewer .paletteBox {
  position: relative;
  width: 50px;
  height: 50px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 6px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.modelViewer .paletteBox:hover {
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.modelViewer .variant_head_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modelViewer .selected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.modelViewer #qrtext {
  padding: 16px 12px;
  margin-left: -8px;
  width: 88%;
  background-color: #fff;
}

.modelViewer #qrtext:hover {
  background-color: #ececec;
}

.modelViewer #qrtext .view_space_btn {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  /* or 117% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  -ms-flex-item-align: start;
  align-self: start;
  color: #000000;
  margin: 0;
  margin-left: 8px;
}

.modelViewer .iconWrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.modelViewer .iconWrapper img {
  height: 34px;
  margin: 0 12px;
}

.modelViewer .iconWrapper .iconTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  /* or 100% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.modelViewer .iconWrapper .shareBtn {
  width: 35px;
  height: 35px;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.modelViewer .iconWrapper .shareBtn img {
  margin-right: 0;
  margin-left: 0;
}

.modelViewer .mobileResponsiveViewer {
  position: relative;
  overflow-x: hidden;
}

.modelViewer .mobileResponsiveViewer .view_space_btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* identical to box height, or 156% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
  margin-left: 12px;
}

.modelViewer .mobileResponsiveViewer .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.modelViewer .mobileResponsiveViewer .header .brand {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #2d3436;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
  position: relative;
  z-index: 9999;
  margin-bottom: 0;
  position: relative;
  top: 0;
  left: 0;
}

.modelViewer .mobileResponsiveViewer .header .proName {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  min-width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 6px 16px;
  /* or 311% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-top: -4px;
  color: #000000;
}

.modelViewer .mobileResponsiveViewer .header .buyNow {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 6px 16px;
  /* or 311% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  cursor: pointer;
  color: #000000;
}

.modelViewer .mobileResponsiveViewer .header .staticModal {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 6px 16px;
  /* or 311% */
  letter-spacing: -1px;
  cursor: pointer;
  color: #000000;
  margin-left: 3px;
}

.modelViewer .mobileResponsiveViewer .header .name {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: bold;
  letter-spacing: -1px;
}

.modelViewer .mobileResponsiveViewer .header .subTitleInstatic {
  font-size: 8px;
  letter-spacing: -1px;
}

.modelViewer .mobileResponsiveViewer .header .shareBtn {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 40px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 8px 8px 8px 0;
}

.modelViewer .mobileResponsiveViewer .variantControls {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 16px;
}

.modelViewer .mobileResponsiveViewer .variantControls .framesWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modelViewer
  .mobileResponsiveViewer
  .variantControls
  .framesWrapper
  .variantListing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 120px;
}

.modelViewer
  .mobileResponsiveViewer
  .variantControls
  .framesWrapper
  .separator {
  height: 50px;
  position: relative;
  top: 0;
  margin: 0 32px;
  border: 0.5px solid #727272;
}

.modelViewer .OverFlowComponents {
  height: 350px;
  overflow: hidden;
  overflow-y: scroll;
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.modelViewer .OverFlowComponents ::-webkit-scrollbar {
  width: 0px;
}

.modelViewer .OverFlowComponents ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modelViewer .OverFlowComponents ::-webkit-scrollbar-thumb {
  background: #ddd;
}

.modelViewer .OverFlowComponents ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.aboutUs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.SignInWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.SignInWrapper .signIn {
  text-align: center;
  margin: 50px auto;
}

.SignInWrapper .signIn .mainHeading {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  margin-bottom: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #2d3436;
}

.SignInWrapper .signIn .inputWrapper {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.SignInWrapper .signIn .inputWrapper .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
  color: #2d3436;
}

.SignInWrapper .signIn .inputWrapper .emailTag {
  margin-right: 12px;
  margin-top: 14px;
  display: inline-block;
}

.SignInWrapper .signIn .inputWrapper .passTag {
  margin-right: 12px;
  margin-top: 14px;
  display: inline-block;
  margin-left: -40px;
}

.SignInWrapper .signIn .inputWrapper .Input {
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  outline: none;
  padding: 12px;
  cursor: pointer;
  width: 282px;
  height: 28px;
  position: relative;
}

.SignInWrapper .signIn .inputWrapper .InputReadOnly {
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  outline: none;
  padding: 12px;
  width: 282px;
  height: 28px;
  position: relative;
}

.SignInWrapper .signIn .inputWrapper .hidePass {
  position: absolute;
  color: #c2c2c2;
  font-size: 15px;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.SignInWrapper .signIn .forgotPass {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 63%;
  margin-bottom: 21px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #2d3436;
}

.SignInWrapper .signIn .submitBtn {
  background: #c2c2c2;
  color: #fff;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  margin-left: 56px;
  font-size: 22px;
  height: 43px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  width: 300px;
  cursor: pointer;
  color: #ffffff;
  text-transform: capitalize;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  margin-bottom: 17px;
}

.SignInWrapper .signIn .submitBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.SignInWrapper .signIn .signwithgoogle {
  padding-top: 4px;
  height: 45px;
  background: #ffffff;
  position: relative;
  border-radius: 2px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  color: #757575;
  width: 217px;
  margin-left: 62px !important;
  margin-bottom: 13px;
  border: 1px solid #9b9090;
}

.SignInWrapper .signIn .microsoftLogin {
  margin-left: 62px !important;
  margin-bottom: 32px !important;
}

.SignInWrapper .signIn .signInor {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 53px;
}

.SignInWrapper .signIn .newUser {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #2d3436;
  margin-left: 62px;
  margin-top: 11px;
  margin-bottom: 34px;
}

.SignInWrapper .signIn .regBtn {
  background: #bdbdbd;
  /* 16 dp • Dark shadow */
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  margin-left: 41px;
}

.SignInWrapper .signIn .newHere {
  margin-bottom: -19px;
}

.getInTouch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 97px;
}

.getInTouch .fileList {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.getInTouch .fileList p {
  margin: 0px 10px;
  border: 1px solid #bdbdbd;
  padding: 8px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 13px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.getInTouch .fileList p:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-color: rgba(146, 180, 211, 0.8);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.getInTouch .fileList .Close {
  font-size: 13px;
  /* margin-left: -34px; */
  position: absolute;
  right: 17px;
  top: 12px;
  color: #918787;
  cursor: pointer;
}

.getInTouch .mainHeading {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
  /* or 75% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #000000;
}

.getInTouch .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 75% */
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
}

.getInTouch .Input {
  width: 704px;
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 0px;
  border: 1px solid #ddd;
  outline: none;
  padding: 12px;
  font-family: "Inter";
  cursor: pointer;
}

.getInTouch .contactRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.getInTouch .submitBtn {
  background: #9b9b9b;
  padding: 16px 40px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 15px;
  margin-top: 52px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  cursor: pointer;
  color: #ffffff;
  text-transform: capitalize;
  width: 166px;
  margin-left: 21px;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.getInTouch .submitBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.getInTouch .upload {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 36px;
  border: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  cursor: pointer;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  background: #bdbdbd;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 15px;
  padding: 6px 29px;
  margin-top: 13px;
  text-transform: capitalize;
}

.getInTouch .upload:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.getInTouch .flexRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.getInTouch .validate {
  text-align: right;
  margin-top: 30px;
}

.blogCard {
  height: 100%;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.blogCard .modelTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 14px;
}

.blogCard .imgBackground {
  border-radius: 16px;
}

.blogCard .imgWrapperOneResp {
  height: 180px;
  width: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 26px;
}

.blogCard .imgWrapperOneResp img {
  border-radius: 20px;
}

.blogCard .imgWrapperOne {
  height: 275px;
  overflow: hidden;
  position: relative;
  border-radius: 26px;
}

.blogCard .imgWrapperOne img {
  border-radius: 20px;
}

.blogCard .imgWrapperLanding {
  height: 275px;
  width: 383px;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
}

.blogCard .imgWrapperLanding img {
  border-radius: 20px;
  height: 166px;
}

.blogCard .imgWrapperLanding:hover img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

.blogCard .imgWrapper {
  height: 250px;
  overflow: hidden;
  position: relative;
}

.blogCard .imgWrapper img {
  border-radius: 20px;
  height: 166px;
}

.blogCard .imgWrapper:hover img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

.blogCard .modelviews {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #999999;
}

.blogCard img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}

.userDashboard {
  margin: 20px auto !important;
}

.userDashboard .sidebar {
  text-align: center;
}

.userDashboard .sidebar .sidebarItem {
  background: #ebebeb;
  width: 123px;
  height: 123px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.userDashboard .sidebar .dash {
  position: absolute;
  left: 100px;
  top: 2px;
}

.userDashboard .sidebar .nameComp {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 48px;
  border-bottom: 0.4px solid #bdbdbd;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  position: relative;
  margin-top: 10px;
  color: #2d3436;
}

.userDashboard .sidebar .performance {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 36px;
  /* identical to box height, or 300% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  /* Black */
  color: #2d3436;
  /* Inside Auto Layout */
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 1.5px 0px;
}

.userDashboard .sidebar .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 32px;
  /* or 229% */
  cursor: pointer;
  /* Black */
  color: #2d3436;
  /* Inside Auto Layout */
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 14px 0px;
}

.userDashboard .sidebar .productBtn {
  background: #ebebeb;
  border-radius: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #666666;
}

.userDashboard .sidebar .productBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .sidebar .onHoverLink {
  text-decoration: none;
}

.userDashboard .sidebar .onHoverLink:hover {
  border-bottom: 1px solid rgba(146, 180, 211, 0.8);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.userDashboard .rightsidebar {
  text-align: center;
}

.userDashboard .rightsidebar .textBelowimg {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  /* Black */
  color: #2d3436;
}

.userDashboard .rightsidebar .CreateBtn {
  background: #bdbdbd;
  /* 16 dp • Dark shadow */
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  /* or 150% */
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  /* White */
  color: #ffffff;
  /* 2 dp • Dark shadow */
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.userDashboard .SettingBtn {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  width: 25%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #fff;
}

.userDashboard .SettingBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .SettingBtnProfile {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  width: 20%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #fff;
}

.userDashboard .SettingBtnProfile:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .ProfilePictureSection {
  text-align: center;
  margin-top: -15px;
  margin-left: 40px;
}

.userDashboard .ProfilePictureSection .text {
  padding: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000000;
}

.userDashboard .ProfilePictureSection .profileright {
  width: 123px;
  height: 123px;
  margin-left: 50px;
  background: #ebebeb;
  border-radius: 61.5px;
}

.userDashboard .ProfilePictureSection .profilePic {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* identical to box height, or 267% */
  width: 70%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #fff;
}

.userDashboard .ProfilePictureSection .profilePic:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .representTitle {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
  padding-left: 45px;
  position: relative;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000000;
}

.userDashboard .representTitle .userIcon {
  position: absolute;
  left: 8px;
  top: 13px;
}

.userDashboard .saveChanges {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  width: 60%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #fff;
}

.userDashboard .saveChanges:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .profilePic {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
}

.userDashboard .inputWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.userDashboard .inputWrapper .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  cursor: pointer;
  text-align: right;
  /* Black */
  color: #2d3436;
}

.userDashboard .inputWrapper .emailTag {
  margin-left: -18px;
  margin-right: 10px;
  margin-top: 3px;
}

.userDashboard .inputWrapper .passTag {
  margin-left: -52px;
  margin-right: 10px;
  margin-top: 3px;
}

.userDashboard .inputWrapper .Input {
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  outline: none;
  width: 282px;
  padding: 12px;
  cursor: pointer;
  margin-left: 10px;
  font-family: Inter;
}

.userDashboard .inputWrapper .InputSelect {
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 16px;
  outline: none;
  width: 573px;
  padding: 5px;
  cursor: pointer;
}

.userDashboard .inputWrapper .rmsc .dropdown-container {
  border: none;
}

.userDashboard .inputWrapper .rmsc .dropdown-heading .dropdown-heading-value {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}

.userDashboard .inputWrapper .fileTag {
  position: absolute;
  left: 84px;
  cursor: pointer;
  top: 13px;
  background: #828282;
  width: 27%;
  padding: 9px;
  border-radius: 9px;
  color: white;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
}

.userDashboard .profileBox {
  width: 123px;
  height: 123px;
  background: #ebebeb;
  border-radius: 16px;
}

.userDashboard .profileright {
  width: 123px;
  height: 123px;
  background: #ebebeb;
  border-radius: 61.5px;
}

.userDashboard .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
  letter-spacing: -0.5px;
  /* Black */
  color: #2d3436;
}

.userDashboard .spanRight {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  /* identical to box height, or 267% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  /* Black */
  color: #2d3436;
}

.userDashboard .selectInvoice {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  /* Black */
  color: #2d3436;
}

.userDashboard .downloadbtn {
  background: #bdbdbd;
  /* 16 dp • Dark shadow */
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  /* or 200% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* White */
  color: #ffffff;
  /* 2 dp • Dark shadow */
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.userDashboard .editIcon {
  font-size: 17px;
  margin-left: 10px;
  color: #a7a6a6;
  margin-top: 14px;
}

.userDashboard .profileBtn {
  background: #e9e9e9;
  border-radius: 11px;
  width: 405px;
  height: 84px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000000;
  text-transform: capitalize;
  margin: 10px;
}

.userDashboard .profileBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .profileBtn .personIcon {
  color: "#8a8a8a";
  font-size: 40px;
  margin-top: 8px;
}

.userDashboard .profileBtn .personIcon:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
}

.userDashboard .cancelPlanBtn {
  background: #e9e9e9;
  border-radius: 11px;
  width: 150px;
  height: 50px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000000;
  text-transform: capitalize;
  margin: 10px;
}

.userDashboard .cancelPlanBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.userDashboard .cancelPlanBtn .personIcon {
  color: "#8a8a8a";
  font-size: 40px;
  margin-top: 8px;
}

.userDashboard .cancelPlanBtn .personIcon:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
}

.productCreate .mainhead {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  margin-bottom: 50px;
  margin-left: 113px;
  color: #2d3436;
}

.productCreate .inputWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.productCreate .inputWrapper .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  cursor: pointer;
  text-align: right;
  /* Black */
  color: #2d3436;
}

.productCreate .inputWrapper .emailTag {
  margin-left: -18px;
  margin-right: 10px;
  margin-top: 3px;
}

.productCreate .inputWrapper .passTag {
  margin-left: -52px;
  margin-right: 10px;
  margin-top: 3px;
}

.productCreate .inputWrapper .Input {
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  outline: none;
  width: 573px;
  padding: 12px;
  cursor: pointer;
}

.productCreate .inputWrapper .InputSelect {
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 16px;
  outline: none;
  width: 573px;
  padding: 5px;
  cursor: pointer;
}

.productCreate .inputWrapper .rmsc .dropdown-container {
  border: none;
}

.productCreate .inputWrapper .rmsc .dropdown-heading .dropdown-heading-value {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}

.productCreate .inputWrapper .fileTag {
  position: absolute;
  left: 84px;
  cursor: pointer;
  top: 13px;
  background: #828282;
  width: 27%;
  padding: 9px;
  border-radius: 9px;
  color: white;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
}

.productCreate .textbelowInput {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: left;
  margin-bottom: 30px;
  color: #828282;
}

.productCreate .browseBtn {
  background: #828282;
  /* 16 dp • Dark shadow */
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin: 10px;
  width: 30%;
  /* or 178% */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.productCreate .checkText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  text-align: left;
  margin-left: 10px;
  text-transform: capitalize;
  /* Gray 3 */
  color: #828282;
}

.productCreate .CreateBtn {
  background: #696969;
  /* 16 dp • Dark shadow */
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  /* or 150% */
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  width: 30%;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 25%;
  /* White */
  margin-bottom: 20px;
  color: #ffffff;
  /* 2 dp • Dark shadow */
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.productCreate .productCreateRight {
  text-align: center;
}

.productCreate .productCreateRight .productContainer {
  padding: 80px !important;
}

.productList .productWrapper {
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.productList .createFirstProductBtn {
  background: #bdbdbd;
  border-radius: 16px;
  margin-top: 30px;
  padding: 10px;
  border: none;
  width: 300px;
  height: 62px;
  color: white;
  font-size: 17px;
  background: #bdbdbd;
  text-transform: capitalize;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
}

.productList .createFirstProductBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.productList .rowheads {
  font-family: Inter;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 0px 12px;
  color: #828282;
}

.productList .searchinput {
  width: 394px;
  height: 49px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  padding-left: 25px;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  border: 1px solid #f7f5f5;
  color: #999999;
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
}

.productList .serachIcon {
  color: #c6bdbd;
  position: absolute;
  top: 4px;
  right: 4%;
}

.productList .productCard {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.productList .productCard .modelTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  text-transform: capitalize;
}

.productList .productCard .imgWrapper {
  height: 145px;
  overflow: hidden;
  position: relative;
}

.productList .productCard .imgWrapper:hover img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}
.productList .productCard .imgWrapper .cloneBtnWrapper {
  border-top-right-radius: 14px;
  transition: border-radius 0.1s;
}

.productList .productCard .imgWrapper:hover .cloneBtnWrapper {
  border-top-right-radius: 0;
}

.productList .productCard .modelviews {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  color: #999999;
}

.productList .productCard img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 85%;
  border-radius: 16px;
}

.productList .productCard .deleteIcon {
  color: #999999;
  font-size: 20px;
}

.productList .productCard .imgAnalyticsIcon {
  position: absolute;
  width: 26px;
  height: 24px;
  left: 83%;
  top: 88%;
  background: #e3e3e3;
  border-radius: 4px;
}

.productList .SeeMoreBtn {
  width: 161px;
  height: 45px;
  background: #ebebeb;
  border-radius: 22.5px;
  cursor: pointer;
  text-transform: capitalize;
}

.productList .CategoryDiv {
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  margin-top: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  width: 100%;
}

.productList .CategoryDiv .Heading {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  /* identical to box height, or 267% */
  margin-left: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  /* Black */
  color: #2d3436;
}

.productList .MuiAccordion-root {
  position: initial;
}

.productList .MuiPaper-elevation1 {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.productList .MuiAccordionSummary-root {
  height: 80px;
}

.productList .cardWrapper {
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  padding: 20px;
  width: 100%;
  text-align: center;
  max-height: 510px;
  overflow: auto;
}

.productList .cardWrapper .theFurnitureBtn {
  background: #ebebeb;
  border-radius: 30.5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 48px;
  /* or 400% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  /* Black */
  width: 18%;
  color: #2d3436;
  margin: 5px auto;
}

.editWrapper {
  margin-top: 20px;
}

.editWrapper .imageUpload {
  opacity: 0;
}

.editWrapper .editLeftWrapper {
  background: #ffffff;
  border: 0.2px solid #e7e3e3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
}

.editWrapper .editLeftWrapper .heading {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #828282;
}

.editWrapper .editLeftWrapper .checkboxWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.editWrapper .editLeftWrapper .checkInput {
  background: #ffffff;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 36px;
  width: 36px;
  position: absolute;
  right: -20%;
}

.editWrapper .editLeftWrapper .checkBox {
  width: 39.54px;
  height: 32px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 10px;
}

.editWrapper .editLeftWrapper .checkText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -1px;
  color: #565656;
  position: relative;
}

.editWrapper .editLeftWrapper .rowHead {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapper .editLeftWrapper .uploadbtn {
  background: #bdbdbd;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  margin-top: 10px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.editWrapper .editRightWrapper {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.editWrapper .editRightWrapper .inlineWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.editWrapper .editRightWrapper ::-webkit-scrollbar {
  width: 0px;
}

.editWrapper .editRightWrapper ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.editWrapper .editRightWrapper ::-webkit-scrollbar-thumb {
  background: #ddd;
}

.editWrapper .editRightWrapper ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editWrapper .editRightWrapper .box1 {
  width: 100%;
  height: 88px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.editWrapper .editRightWrapper .collapse {
  min-height: 0px;
  height: auto;
  -webkit-transition-duration: 222ms;
  transition-duration: 222ms;
}

.editWrapper .editRightWrapper .downArrow {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #bdbdbd;
  font-size: 30px;
}

.editWrapper .editRightWrapper .smallBox {
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  background: #e2e2e2;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 17px;
}

.editWrapper .editRightWrapper .compName {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.editWrapper .editRightWrapper .buyNow {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 10px;
  color: #000000;
}

.editWrapper .editRightWrapper .fromtext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  margin-left: 10px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.editWrapper .editRightWrapper .componenttext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 311% */
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: capitalize;
  margin-bottom: 20px;
  color: #565656;
}

.editWrapper .editRightWrapper .secondBox {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0px 10px;
}

.editWrapper .editRightWrapper .default {
  margin-left: 48px;
  margin-top: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  color: #5b5b5b;
}

.editWrapper .editRightWrapper .addComp {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 56px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  text-align: left;
  padding-left: 24px;
}

.editWrapper .editRightWrapper .addComp .text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  cursor: pointer;
  /* identical to box height, or 311% */
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapper .editRightWrapper .saveBtn {
  background: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 106%;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.editWrapper .editRightWrapper .saveBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.editWrapper .editRightWrapper .chatBoxEdit {
  margin-top: 30px;
}

.editWrapper .editRightWrapper .chatBoxEdit p {
  font-family: Inter;
  padding-left: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapper .editRightWrapper .chatBoxEdit span {
  font-family: Inter;
  padding-left: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  cursor: pointer;
  margin-bottom: 20px;
  color: #565656;
}

.editWrapper .editRightWrapper .chatBoxEdit .chatBox {
  height: 170px;
  width: 105%;
  background: #ffffff;
  border: 0.5px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  outline: none;
}

.editWrapper .editRightWrapper .chatBoxEdit .chatBox:focus {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-color: rgba(146, 180, 211, 0.8);
}

.editWrapper .editRightWrapper .chatBoxEdit .chatBoxResp {
  height: 170px;
  width: 105%;
  background: #ffffff;
  border: 0.5px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  outline: none;
}

.editWrapper .editRightWrapper .chatBoxEdit .chatBoxResp:focus {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-color: rgba(146, 180, 211, 0.8);
}

.editWrapperResp {
  margin-top: 440px;
}

.editWrapperResp .imageUpload {
  opacity: 0;
}

.editWrapperResp .editLeftWrapper {
  background: #ffffff;
  border: 0.2px solid #e7e3e3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
}

.editWrapperResp .editLeftWrapper .heading {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #828282;
}

.editWrapperResp .editLeftWrapper .checkboxWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.editWrapperResp .editLeftWrapper .checkInput {
  background: #ffffff;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 36px;
  width: 36px;
  position: absolute;
  right: -20%;
}

.editWrapperResp .editLeftWrapper .checkBox {
  width: 39.54px;
  height: 32px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 10px;
}

.editWrapperResp .editLeftWrapper .checkText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapperResp .editLeftWrapper .rowHead {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapperResp .editLeftWrapper .uploadbtn {
  background: #bdbdbd;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  margin-top: 10px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.editWrapperResp .editRightWrapper {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.editWrapperResp .editRightWrapper .inlineWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.editWrapperResp .editRightWrapper ::-webkit-scrollbar {
  width: 0px;
}

.editWrapperResp .editRightWrapper ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.editWrapperResp .editRightWrapper ::-webkit-scrollbar-thumb {
  background: #ddd;
}

.editWrapperResp .editRightWrapper ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editWrapperResp .editRightWrapper .box1 {
  width: 100%;
  height: 88px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.editWrapperResp .editRightWrapper .collapse {
  min-height: 0px;
  height: auto;
  -webkit-transition-duration: 222ms;
  transition-duration: 222ms;
}

.editWrapperResp .editRightWrapper .downArrow {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #bdbdbd;
  font-size: 30px;
}

.editWrapperResp .editRightWrapper .smallBox {
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  background: #e2e2e2;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 17px;
}

.editWrapperResp .editRightWrapper .compName {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.editWrapperResp .editRightWrapper .buyNow {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 10px;
  color: #000000;
}

.editWrapperResp .editRightWrapper .fromtext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  margin-left: 10px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.editWrapperResp .editRightWrapper .componenttext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 311% */
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: capitalize;
  margin-bottom: 20px;
  color: #565656;
}

.editWrapperResp .editRightWrapper .secondBox {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0px 10px;
}

.editWrapperResp .editRightWrapper .default {
  margin-left: 48px;
  margin-top: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  color: #5b5b5b;
}

.editWrapperResp .editRightWrapper .addComp {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 56px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  text-align: left;
  padding-left: 24px;
}

.editWrapperResp .editRightWrapper .addComp .text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  cursor: pointer;
  /* identical to box height, or 311% */
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapperResp .editRightWrapper .saveBtn {
  background: #bdbdbd;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 106%;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.editWrapperResp .editRightWrapper .saveBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.editWrapperResp .editRightWrapper .chatBoxEdit {
  margin-top: 30px;
}

.editWrapperResp .editRightWrapper .chatBoxEdit p {
  font-family: Inter;
  padding-left: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.editWrapperResp .editRightWrapper .chatBoxEdit span {
  font-family: Inter;
  padding-left: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  cursor: pointer;
  margin-bottom: 20px;
  color: #565656;
}

.editWrapperResp .editRightWrapper .chatBoxEdit .chatBox {
  height: 170px;
  width: 105%;
  background: #ffffff;
  border: 0.5px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  outline: none;
}

.editWrapperResp .editRightWrapper .chatBoxEdit .chatBox:focus {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-color: rgba(146, 180, 211, 0.8);
}

.editWrapperResp .editRightWrapper .chatBoxEdit .chatBoxResp {
  height: 170px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  outline: none;
}

.editWrapperResp .editRightWrapper .chatBoxEdit .chatBoxResp:focus {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-color: rgba(146, 180, 211, 0.8);
}

.modalWrapper {
  text-align: center;
}

.modalWrapper .contentWrap {
  width: 80%;
  margin: auto;
  margin-top: 15px;
}

.modalWrapper .contentWrap h4 {
  padding: 9px;
  font-size: 21px;
  margin-top: 22px;
}

.modalWrapper .contentWrap p {
  text-align: left;
  margin-bottom: 10px;
  padding-left: 7px;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
}

.modalWrapper .contentWrap p .uploadbtn {
  border: 1px solid #bdbdbd;
  width: 100%;
  margin-top: 21px;
  text-transform: capitalize;
  background: #bdbdbd73;
}

.modalWrapper .modalHead {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  /* identical to box height, or 229% */
  text-align: center;
  color: #000000;
}

.modalWrapper .modalBox {
  width: 105px;
  height: 105px;
  margin: 10px auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.modalWrapper .inputTextWrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 10px;
  margin-left: 25px;
}

.modalWrapper .inputTextWrap2 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 10px;
  margin-left: 25px;
}

.modalWrapper .modalInput {
  width: 96%;
  height: 37px;
  border: 1px solid #ddd;
  background: #ffffff;
  -webkit-box-shadow: inset 2px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 2px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.modalWrapper .modalText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  /* identical to box height, or 229% */
  margin-left: -26px;
  margin-right: 10px;
  cursor: pointer;
  color: #000000;
}

.modalWrapper .modalMaterialHead {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 10px;
  /* identical to box height, or 229% */
  margin-top: 12px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #e5e1e1;
  color: #000000;
}

.modalWrapper .uploadbtnswatch {
  width: 160px;
  height: 36px;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  /* or 257% */
  margin: 20px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #616161;
  position: relative;
}

.modalWrapper .uploadbtnswatchPicker {
  width: 160px;
  height: 36px;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  /* or 257% */
  margin: 9px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #616161;
  position: relative;
  bottom: 280px;
}

.modalWrapper .uploadbtnswatch1 {
  width: 160px;
  height: 36px;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  /* or 257% */
  margin: 36px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #616161;
  position: relative;
}

.modalWrapper .uploadbtnswatchPicker1 {
  width: 160px;
  height: 36px;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  /* or 257% */
  margin: 9px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #616161;
  position: relative;
}

.modalWrapper .MuiInput-underline:before {
  border-bottom: none;
}

.modalWrapper .addBtn {
  background: #bdbdbd;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-left: 19px;
  letter-spacing: 0.25px;
  margin-top: 20px;
  width: 61%;
  padding: 8px;
  text-transform: capitalize;
  color: #ffffff;
}

.modalWrapper .addBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.modalWrapper .varticalLine {
  width: 1px;
  position: absolute;
  left: 50%;
  height: 33px;
}

.confirmationModal {
  text-align: center;
  padding: 34px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 90px;
}

.confirmationModal .DeleteBtn {
  background-color: #ea4747;
  padding: 3px 10px;
  border-radius: 6px;
  color: #fff;
  margin: 10px;
  width: 30%;
  height: 36px;
  text-transform: capitalize;
}

.confirmationModal .DeleteBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.selected {
  border-bottom: 3px solid #828282;
}

.productUpload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.productUpload .editRightWrapper {
  text-align: left;
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.productUpload .editRightWrapper .inlineWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.productUpload .editRightWrapper ::-webkit-scrollbar {
  width: 0px;
}

.productUpload .editRightWrapper ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.productUpload .editRightWrapper ::-webkit-scrollbar-thumb {
  background: #ddd;
}

.productUpload .editRightWrapper ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.productUpload .editRightWrapper .box1 {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 15px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
}

.productUpload .editRightWrapper .box1 .Input {
  width: 96%;
  height: 37px;
  margin-top: 10px;
  border: 1px solid #ddd;
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.productUpload .editRightWrapper .downArrow {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #bdbdbd;
  font-size: 30px;
}

.productUpload .editRightWrapper .smallBox {
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  background: #e2e2e2;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 17px;
}

.productUpload .editRightWrapper .compName {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.productUpload .editRightWrapper .buyNow {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  margin-bottom: 10px;
  color: #000000;
  margin-top: 10px;
}

.productUpload .editRightWrapper .fromtext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  margin-left: 10px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #000000;
}

.productUpload .editRightWrapper .componenttext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  /* identical to box height, or 311% */
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: #565656;
}

.productUpload .editRightWrapper .secondBox {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0px 10px;
}

.productUpload .editRightWrapper .default {
  margin-left: 48px;
  margin-top: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -1px;
  color: #5b5b5b;
}

.productUpload .editRightWrapper .addComp {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 328px;
  height: 56px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  text-align: left;
  padding-left: 24px;
}

.productUpload .editRightWrapper .addComp .text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 56px;
  cursor: pointer;
  /* identical to box height, or 311% */
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  color: #565656;
}

.productUpload .editRightWrapper .saveBtn {
  background: #bdbdbd;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  width: 60%;
  margin-top: 20px;
  margin-left: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  color: #ffffff;
  text-transform: capitalize;
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
}

.productUpload .editRightWrapper .saveBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.productUpload .PreeditWrapper {
  text-align: left;
}

.productUpload .PreeditWrapper .addIcon {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 36px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  margin-bottom: 39px;
  color: #bdbdbd;
}

.productUpload .PreeditWrapper .uploadBtn {
  background: #bdbdbd;
  color: white;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  border-radius: 16px;
  height: 54px;
  width: 200px;
  margin-bottom: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.productUpload .PreeditWrapper .uploadBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.productUpload .PreeditWrapper .acceptedText {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */
  text-align: center;
  /* Gray 3 */
  color: #828282;
  margin-bottom: 10px;
}

.productUpload .PreeditWrapper .normalText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  /* Gray 3 */
  color: #828282;
}

.productUpload .PreeditWrapper .OrText {
  position: absolute;
  left: 48%;
  top: -13px;
  background: #fff;
  color: #828282;
  width: 36px;
}

.productUpload .PreeditWrapper .hrLine {
  border-bottom: 1px solid #a4a4a4;
}

.paymentsettings {
  margin-top: 30px !important;
}

.paymentsettings .sidebar {
  text-align: center;
}

.paymentsettings .sidebar .sidebarItem {
  background: #ebebeb;
  width: 123px;
  height: 123px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 16px;
}

.paymentsettings .sidebar .nameComp {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  border-bottom: 0.4px solid #bdbdbd;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  /* Black */
  margin-top: 10px;
  color: #2d3436;
}

.paymentsettings .sidebar .performance {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 36px;
  /* identical to box height, or 300% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.25px;
  /* Black */
  color: #2d3436;
  /* Inside Auto Layout */
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 1.5px 0px;
}

.paymentsettings .sidebar .spanTag {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
  /* or 229% */
  /* Black */
  color: #2d3436;
  /* Inside Auto Layout */
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 14px 0px;
}

.paymentsettings .sidebar .productBtn {
  background: #ebebeb;
  border-radius: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  /* identical to box height, or 267% */
  width: 90%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #666666;
}

.paymentsettings .sidebar .productBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.paymentsettings .sidebar .onHoverLink {
  text-decoration: none;
}

.paymentsettings .sidebar .onHoverLink:hover {
  border-bottom: 1px solid rgba(146, 180, 211, 0.8);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.paymentsettings .SettingBtn {
  background: #bdbdbd;
  border-radius: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 267% */
  width: 25%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  position: relative;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  margin-bottom: 30px;
  color: #666666;
}

.paymentsettings .SettingBtn:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}

.paymentsettings .mainText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  padding: 0px 0px 10px 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: #000000;
  position: relative;
}

.paymentsettings .masterCard {
  background: #c4c4c4;
  border-radius: 7px;
  width: 82px;
  height: 45px;
}

.shareModal {
  padding: 25px;
}

.shareModal .textMain {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #565656;
  display: inline-block;
  margin-bottom: 24px;
}

.shareModal .input {
  border: 0.5px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  outline: none;
}

.shareModal .CopyBtn {
  background: #c4c4c4;
  border-radius: 7px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */
  position: absolute;
  top: 10px;
  right: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: #ffffff;
}

.shareModal .CopyBtn:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.selectedLink {
  color: #2874f0;
  border-bottom: 5px solid #efefef;
  font-weight: 900;
}

.privacyPolicy {
  padding: 50px;
}

.privacyPolicy .descripttag {
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding: 0px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #585656;
}

.blog_detail {
  padding: 40px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog_detail .blogHead {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 70px;
}

.blog_detail .blogHeadResp {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 100px;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 70px;
}

.blog_detail .descript {
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2d3436;
  cursor: pointer;
  text-align: left;
  padding-left: 50px;
}

.blog_detail .blogImaage {
  width: 100%;
  height: 300px;
  border-radius: 8px;
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
  .web {
    display: none;
  }
  .responsive {
    display: block;
  }
  .shareModal {
    padding: 25px;
  }
  .shareModal .MuiButton-root {
    min-width: 51px;
  }
  .shareModal .textMain {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #565656;
    display: inline-block;
    margin-bottom: 24px;
  }
  .shareModal .input {
    border: 0.5px solid #000000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    outline: none;
  }
  .shareModal .CopyBtn {
    background: #c4c4c4;
    border-radius: 7px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 133% */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    letter-spacing: -1px;
    text-transform: capitalize;
    color: #ffffff;
  }
  .productList .rowheads {
    font-size: 13px !important;
  }
}
