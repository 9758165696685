.modelTemplateWrapper {
    font-family: "Inter" !important;

}

.modelTemplateWrapper #canvasWrapper {
    height: 100%;
}

.modelTemplateWrapper #canvasWrapper canvas {
    height: auto;
    width: 100%;
}

.modelTemplateWrapper .newShadow {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
}

.modelTemplateWrapper .uploadImageBtn {
    border-radius: 7px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.modelTemplateWrapper .formEle {
    padding: 12px;
    border-radius: 7px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.modelTemplateWrapper label,
.modelTemplateWrapper input {
    display: block;
    font-size: 17px;
    box-sizing: border-box;
    width: 100%;
}

.modelTemplateWrapper label {
    margin-bottom: 8px;
}

.modelTemplateWrapper .dimensionWrapper {
    box-sizing: border-box;
    height: 88%;
    padding: 16px 24px;
    border-radius: 7px;

    h4 {
        font-weight: normal;
    }
}

.modelTemplateWrapper .uploadBtn,
.agreementCard .uploadBtn {
    width: 100%;
    border-radius: 7px;
    margin-top: 12px;
    height: 62px;
    outline: none;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 20px;
    border: none;
    background: rgba(189, 189, 189, 1);
    transition: 0.3s;
}

.modelTemplateWrapper .uploadBtn:hover,
.agreementCard .uploadBtn:hover {
    transition: 0.3s;
    background-color: rgba(146, 180, 211, 0.8);
    color: #fff;
}