.shareUser {
  background: white;
  border: 1px solid #ddd;
  outline: 0;
  margin-top: 35px;
  width: 100%;
  font-size: 18px;
  padding: 15px;

  text-overflow: ellipsis;

  overflow: hidden;

  white-space: nowrap;

  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: #666666;
  background: #ebebeb;
}

.shareUser:hover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: rgba(146, 180, 211, 0.8);
  color: #fff;
}
