.addHotspotdrawer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* height: 100%;
  max-height: 100%; */
  z-index: 2147483630;
  transform: translateX(200vw);
  transition: all 0.3s;
}
.addHotspotdrawer.show {
  transform: translateX(0);
}

.drawer_content_container::-webkit-scrollbar {
  width: 0px;
  display: none !important                                                                                                                                                                                     ;
}
.drawer_content_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.drawer_content_container::-webkit-scrollbar-thumb {
  background: #888;
}
