.annotBtnWrapper__XX_TITLE {
  opacity: 0;
  width: 0;
  word-wrap: unset;
  word-break: keep-all;
  line-height: 1;
  transition: all 0.3s;
}

.annotBtnWrapper__XX.show .annotBtnWrapper__XX_TITLE {
  opacity: 1;
  min-width: max-content;
  width: max-content;
  padding: 0 1rem 0 0.5rem;
  border: 0px solid red;
}

.annotBtnWrapper__XX.clicked .annotBtnWrapper__XX_TITLE {
  opacity: 1;
  min-width: max-content;
  width: max-content;
  padding: 0 1rem 0 0.5rem;
  border: 0px solid red;
}
