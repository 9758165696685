.product_share_pop_up * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.product_share_pop_up button {
  cursor: pointer;
  opacity: 1;
  transition: all 300ms;
}

.product_share_pop_up button:hover {
  opacity: 0.8;
}
