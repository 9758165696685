.sun-editor {
  border: 0px solid blue !important;
  height: 100% !important;
  max-height: 100% !important;
  overflow: visible !important;
}

.se-container {
  /* overflow: auto !important; */
  overflow: none !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  border: 1px solid #ddd !important;
}
.se-wrapper {
  height: 100% !important;
  /* border: 1px solid red; */
}
.sun-editor-editable {
  /* border: 1px solid #ddd !important; */
  overflow: auto !important;
  /* max-height: 600px !important; */
}
.se-resizing-bar.sun-editor-common {
  display: none;
}
