.CREATE_SHARE,
.CREATE_SHARE * {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  /* border: 1px solid red; */
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
